<template>
    <CriarStakeholder />
</template>

<script>
import CriarStakeholder from '@/components/Stakeholder/CriarStakeholder'
export default {
  components: {CriarStakeholder},
  data(){
    return{}
  }
}
</script>

<style>

</style>